// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import 'app.variables';

body {
  font-family: $main-font-family;

  h1,h2,h3,h4,h5,h6{
    font-family: $hight-font-family;
  }

  &#login-layout{
    background-color: $second-body-color;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .panel-login{
      position: absolute;
      width: 100%;
      height: 100%;
      max-width: 400px;
      height: auto;
      max-height: 550px;
      right: 50%;
      bottom: 50%;
      transform: translate(50%, 50%);
      border-radius: 5px;
      box-shadow: 0px 1px 3px rgba(169, 169, 169, 0.3);
      background: $main-body-color;
      overflow: hidden;
      color: white;


      h2{
        text-align: center;
        font-size: 2rem;
        border-bottom: 2px solid;
        margin-bottom: 1.5rem;
        padding-bottom: 1rem;
        font-weight: bold;
      }

      .logo-login{
        padding: 2rem 7rem;
        background: white;
      }


      label{
        font-family: $hight-font-family;
        margin-top: 0.1rem;
        margin-top: 0.5rem;
        margin-bottom: 0;
      }

      small{
        text-align: center;
        line-height: 1;
        display: block;
        padding: 1rem 0 2rem;
        font-size: 1.3rem;
      }

      .btn{
        border-color: white;
        color: white;

        &:hover, &:active{
          background-color: white;
          color: $main-body-color;
        }
      }

      .has-error .help-block{
        strong{
          color: white;
        }

      }


    }

    footer{
      position: absolute;
      bottom: 0;
      padding: 2rem 0;
      color: white;
      width: 100%;
      font-weight: bold;

    }
  }

  &#app-layout{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    .border-section{
      border-left: 1px solid $main-body-color;
      min-height: 100%;

      .container-fluid{
        min-height: 600px;
      }
    }

    footer{
      padding: 1rem 0 2rem;
      background: $main-body-color;
      color: white
    }
  }
}

.content-center{
  width: 100%;
  max-width: 900px;
  margin: 2rem auto 2rem ;
}

.navbar{
  margin-bottom: 0;
}

.banner{
  img{
    width: 100%;
  }
}



.content-producto{
  margin: 1rem auto 2rem;
  transition: 0.28s all ease-in;
  cursor: pointer;
  border: 1px solid $main-body-color;
  height: 285px;
  background-color: $main-body-color;

  &:hover{
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
  }

  h3{
    margin-top: 0;
    margin-bottom: 1.5rem;
  }
  .description{
    text-align: center;
    color: white;
    text-align: center;
    display: block;
    font-size: 1.8rem;
    margin: 0.5rem auto 0;
  }

  .btn{
    width: 100%;
  }
}

.content-producto {
  .box-description{
    background-color: #a8342e;
    color: white;
    padding: 1rem;

    strong{
      color: white;
    }

    .btn {
      margin: 0 auto;
      width: 60%;
      display: block;
      border-color: white;
      color: white;

      &:hover, &.active{
        background-color: white;
        color: #a8342e;
      }
    }
  }
}


.side-menu{
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;



  & > li{
    list-style: none;
    background: transparent;
    background-color: $main-body-color;
    color: white;

    label, li{
      padding: 1rem;
      transition: 0.28s all ease-out;

    }

    ul{
      background: white;
      margin-bottom: 0;

      li{
        position: relative;

        a{
          position: absolute;
          width: 100%;
          height: 100%;
        }

        &:hover{
          background-color: $second-body-color;
          a{
            color: white;
            text-decoration: none;
          }
        }
      }
    }

  }
}

.main-content{
  min-height: 100%;
  height: auto;
  max-width: 1280px;
  margin: 0 auto !important;
}

.row-no-padding {
  & > [class*="col-"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.btn{
  background: transparent;
  border-color: $main-body-color;
  transition: all 0.28s ease-out;
  color: $main-body-color;

  &:hover{
    background-color: $main-body-color;
    color: white;
    border-color: white;
  }
}
.form-inline .form-group{
  margin: 15px 1rem;
}
.btn-red{
  background-color: #d9534f;
  border-color: #d43f3a;
  color: white;

  &:hover{
    background-color: #d43f3a;
    color: white;
  }
}
.fa-btn {
  margin-right: 6px;
}

.row{
  margin: 0;
}


.container > h2,
.content-center > h2{
  color: #a8342e;
  font-weight: bold;
  text-align: center;
}

.content-center
h2{
  color: #a8342e;
  margin: 0;
}

h3{
  margin: 0.5rem auto 2rem;
  font-size: 2rem;
}

h4{
  line-height: 1.5;
}

.content-desc{
  margin-top: 5rem;

  h4{
    background-color: #a8342e;
    color: white;
    padding: 1rem;
    margin: 0;
    width: 150px;
    text-align: center;
  }

  ul{
    border: 1px solid #a8342e;
    padding-top: 2rem;
    padding-right: 1rem;
  }
}

.admin-navbar{
  background-color: $main-body-color;

  .navbar{
    max-width: 1280px;
    margin: 0 auto;
    background-color: $main-body-color;
    min-height: 43px;
    border: none;

    & .navbar-nav>li>a,
    & .navbar-nav>li>a:hover,
    & .navbar-nav>li>a:active{
      color: white;
    }

    & .navbar-nav>.open>a{
      background-color: $second-body-color;
      color: white;
    }

    & > .container .navbar-brand{
      background: white;
      position: absolute;
      height: 86px;
      box-shadow: 0px 1px 2px rgba(0,0,0,0.5);
      top: -6px;
      left: 10px;
    }
  }
  .navbar-default .navbar-collapse{
    padding-left: 8rem;
  }

  .dropdown-menu{
    background-color: $second-body-color;

    & > li > a{
      color: white;
      &:hover{
        background-color: $main-body-color;
      }
    }
  }

}

.breadcrumb{
  margin-top: 5rem;
  background: transparent;
  margin-bottom: 0;
}

img{
  max-width: 100%;
  height: auto;
}

table{
  thead{
    background-color: $main-body-color;
    color: white;
  }
}


.navbar-brand{
  & > img {
    height: 100%;
  }
}

.logo{
  height: 90px;
  margin-bottom: 2rem;
}

.image-uniforme{
  display: inline-block;
  width: 200px;
  margin: auto 10px;
}

table{
  img{
    width: 80px;
  }

  span{
    display: block;
    margin-bottom: 10px;
  }
}

.slider-nav{
  margin-top: 1rem;

  .content-img{
    margin: 0 1rem;

    small{
      display: block;
      text-align: center;
      font-weight: bold;
      text-transform: capitalize;
    }
  }
}

.color_selector{

  span{
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    cursor: pointer;
    vertical-align: middle;

    &.active{
      border-color: #5bc0de;
      border-width: 3px;
      vertical-align: middle;
    }

    input{
      visibility: hidden;
    }
  }
}

.form-group.no-margin{
  margin-bottom: 0;
}

.form-group.no-padding {
  padding: 0;
}

.content-shop{
  padding: 0 4rem;
}

.breadcrumb a{
  color: #a8342e;
}

table .text-righ{
  font-size: 1.8rem;
}

strong{
  color: #a8342e;
}

.no-padding{
  padding: 0;
}


.carrito-compras{
  & > ul{
    list-style: none;

    & > li{
      border-bottom: 1px solid $main-body-color;
      padding: 2rem 0;

      ul{
        margin-top: 0.5rem;

      }
    }
  }

  h3{
    color: $main-body-color;
    font-weight: bold;
    margin-bottom: 0;
  }

  h4{
    margin-top: 0;
  }
}
